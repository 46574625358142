// Importam componentele ce tin de rutare.
import { Route, Routes } from "react-router-dom";
// Importam paginile.
import Page404 from "./pages/Page404";
import Page404Doc from "./pages/Page404Doc";
import Home from "./pages/Home";
import Redirect from "./pages/Redirect";
// import Tsb from "./pages/Tsb";

function App() {
  return (
    <div className="App">
      {/* Pasam state-ul global si dispatch-ul catre intreaga aplicatie. */}

      {/* Rutarea clasica, similara cu ce am facut la sedinta 32. */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Page404 />} />
        {/* <Route path="servicebox/doc/:vin/:tsb/:h/:w" element={<Tsb />} /> */}
        <Route path="servicebox/doc/*" element={<Page404Doc />} />
        <Route path="servicebox/resources/*" element={<Redirect />} />
      </Routes>
    </div>
  );
}

export default App;
