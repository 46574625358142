import React from "react";
import myImage from "./stellantis_logo.jpg";

function Home() {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={myImage} alt="stelantis" />
    </div>
  );
}

export default Home;
