import React, { useState } from "react";
import { vinUpload } from "../source/api";

function Page404() {
  const [mesajVin, setMesajVin] = useState("");
  const [color, setColor] = useState("black");

  const tsb = new URLSearchParams(window.location.search).get("tsb");
  const vin = new URLSearchParams(window.location.search).get("vin");

  async function addVin() {
    const addVin2 = await vinUpload({ vin: vin, tsb: tsb });
    setMesajVin(addVin2.mesaj);
    setColor(addVin2.color);
  }

  let h = window.innerHeight;

  return (
    <div style={{ height: h }}>
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div>TSB-ul {tsb} nu exista in baza de date!</div>
          <div>Introduceti seria de caroserie in lista: {vin}</div>
          <button onClick={addVin}>Adauga</button>
          <div style={{ color: `${color}` }}>{mesajVin}</div>
        </div>
      </div>
    </div>
  );
}

export default Page404;
