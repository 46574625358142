import React from "react";
import { url } from "../source/url";

function Redirect() {
  const id = new URLSearchParams(window.location.search).get("id");

  console.log(id);

  window.location.replace(`${url}/servicebox/doc/${id}.html`);

  return <div></div>;
}

export default Redirect;
