import axios from "axios";
import { apiUrl } from "./url";

export const vinUpload = async (data) => {
  try {
    await axios.post(apiUrl, { data });
    return {
      mesaj: "TSB-ul a fost adaugat impreuna cu vin-ul!",
      color: "green",
    };
  } catch (error) {
    // throw error;
    return { mesaj: error.response.data.message, color: "red" };
  }
};
export const getVins = async () => {
  try {
    const { data } = await axios.get(apiUrl);
    return data;
  } catch (error) {
    throw error;
  }
};

// export const deleteFile = async (data) => {
//   try {
//     await axios.delete(apiUrl + `deleteFile/${data._id}`);
//   } catch (error) {
//     throw error;
//   }
// };
